import {useState} from 'react'
import '../styles/Nav.css'


function Nav({tree, height, width}){
    const [isOpen, setIsOpen] = useState(false);
    const [hNav, setHNav] = useState("hidden");
    let circle= (width<650)?
                <div className='circle'></div>
                :<div className='circle' 
                onMouseOver={()=>setHNav("visible")}
                onMouseOut={()=>setHNav("hidden")}></div>
    return(
        (isOpen)?
        (
        <div className='closed-click' style={{width: width, height:height}} onClick={(e)=>setIsOpen(false)}>
        <nav className='nav-o' style={{height:height}} onClick={(e)=>setIsOpen(false)}>
            <ul>
                {tree.map(({id,name, state})=>
                    (!state[0])? (
                        <a href={`#${id}`}><div className='h-nav-o' key={id}>
                        <li className='h-item-o'>{name}</li>
                        <div className='circle-o'></div>
                    </div></a>) : null
                )}
                <div className='nav-tab' onClick={()=>setIsOpen(false)}></div>
            </ul>
        </nav></div>):
        (<nav className='nav' style={{height:height}}>
            <ul className='not-o'>
                {tree.map(({id,name, state})=>
                    (!state[0])? (
                        <a className='zIndex' href={`#${id}`}>
                        <li className='h-item' style={{visibility:hNav}}>{name}</li>
                        {circle}
                        </a>
                    ) : null
                )}
                <div className='nav-tab' onClick={()=>setIsOpen(true)}></div>
            </ul>
        </nav>)
    )
}
export default Nav;