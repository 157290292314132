import '../styles/Home.css';


function Home({tree, height, width}){
    const lineJump=(width<650)? <br/> : "";

// à mettre dans une fontion séparé
    window.addEventListener("load", function(e){
        let boxElement=document.querySelector('.home');
        createObserver(boxElement);
    },false);
    function createObserver(boxElement){
        let observer;
        let options={
            root:null,
            rootMargin: "0px",
            threshold: [0,0.25,0.5,0.75,1],
        }
        observer= new IntersectionObserver(handleIntersect, options);
        observer.observe(boxElement);
    }
    function handleIntersect(entries, observer){
        tree[0].state[1](entries[0].intersectionRatio>0.5);
    }
// à mettre dans une fontion séparé
    // visibilityObserver('.home')
    return(
        <section id={tree[0].id} className="home" style={{width: width, height:height}}>
        <div>
            <h1 ><span>Intern</span> {lineJump}Developer</h1>
        </div>
        </section>    
    )
}
export default Home