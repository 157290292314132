import { skillsList } from "../datas/skillsList";
import Skill from "./Skill";
import '../styles/Skills.css'

function Skills({tree, height,width}){
    
    // à mettre dans une fontion séparé
    window.addEventListener("load", function(e){
        let boxElement=document.querySelector('.skills');
        createObserver(boxElement);
    },false);
    function createObserver(boxElement){
        let observer;
        let options={
            root:null,
            rootMargin: "0px",
            threshold: [0,0.25,0.5,0.75,1],
        }
        observer= new IntersectionObserver(handleIntersect, options);
        observer.observe(boxElement);
    }
    function handleIntersect(entries, observer){
        tree[2].state[1](entries[0].intersectionRatio>0.5);
    }
// à mettre dans une fontion séparé  
    // visibilityObserver('.skills'); ->impossible pour l'instant de faire remonter le "isVisible"
    return(
        <section id={tree[2].id} className="skills" style={{width: width, height:height}}>
            <div className='skills-gradient'style={{width: width, height:height}}>
                <h2 className="h-skills">Skills</h2>
                <ul className='skills-list'>
                    {
                        skillsList.map(({name,state,logo})=>
                        <div key={name}  className='skill-list'>
                            <Skill
                                name={name}
                                state={state}
                                logo={logo}
                            />
                        </div>)
                    }
                </ul>
            </div>
        </section>
    )
}
export default Skills