import angular from '../assets/Skills/angular.png'
import css from '../assets/Skills/css.png'
import expressJs from '../assets/Skills/Expressjs.png'
import git from '../assets/Skills/git.png'
import html from '../assets/Skills/html.png'
import mongoDB from '../assets/Skills/mongoDB.png'
import mysql from '../assets/Skills/mysql.jpg'
import neo4j from '../assets/Skills/neo4j.png'
import nodeJS from '../assets/Skills/nodeJS.png'
import powerBi from '../assets/Skills/powerBi.png'
import python from '../assets/Skills/python.png'
import react from '../assets/Skills/react.png'
import django from '../assets/Skills/django.svg'

export const skillsList=[
    {
        name:'Angular',
        state:0,
        description:"",
        source:"",
        practice:"",
        logo:angular
    },
    {
        name:'CSS',
        state:35,
        description:"",
        source:"",
        practice:"",
        logo:css
    },
    // {
    //     name:'Express JS',
    //     state:0,
    //     description:"",
    //     source:"",
    //     practice:"",
    //     logo:expressJs
    // },
    {
        name:'Git',
        state:45,
        description:"",
        source:"",
        practice:"",
        logo:git
    },
    {
        name:'HTML',
        state:45,
        description:"",
        source:"",
        practice:"",
        logo:html
    },
    {
        name:'Mongo DB',
        state:0,
        description:"",
        source:"",
        practice:"",
        logo:mongoDB
    },
    {
        name:'My SQL',
        state:25,
        description:"",
        source:"",
        practice:"",
        logo:mysql
    },
    {
        name:'Neo4J',
        state:20,
        description:"",
        source:"",
        practice:"",
        logo:neo4j
    },
    {
        name:'Node JS',
        state:0,
        description:"",
        source:"",
        practice:"",
        logo:nodeJS
    },
    {
        name:'Power Bi',
        state:15,
        description:"",
        source:"",
        practice:"",
        logo:powerBi
    },
    {
        name:'Python',
        state:20,
        description:"",
        source:"",
        practice:"",
        logo:python
    },
    {
        name:'React',
        state:25,
        description:"",
        source:"",
        practice:"",
        logo:react
    },
    {
        name:'Django',
        state:35,
        description:"",
        source:"",
        practice:"",
        logo:django
    }
]
