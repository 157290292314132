import '../styles/GetInTouch.css';
import Footer from './Footer';
import send from '../assets/send.png';

function GetInTouch({tree, width, height}){
     // à mettre dans une fontion séparé
     window.addEventListener("load", function(e){
        let boxElement=document.querySelector('.get-in-touch');
        createObserver(boxElement);
    },false);
    function createObserver(boxElement){
        let observer;
        let options={
            root:null,
            rootMargin: "0px",
            threshold: [0,0.25,0.5,0.75,1],
        }
        observer= new IntersectionObserver(handleIntersect, options);
        observer.observe(boxElement);
    }
    function handleIntersect(entries, observer){
        tree[3].state[1](entries[0].intersectionRatio>0.5);
    }
// à mettre dans une fontion séparé    
    // visibilityObserver('.get-in-touch');

    return(
        <section id={tree[3].id} className="get-in-touch"  style={{width: width, height:height}}>
            <div className='git-gradient'style={{width: width, height:height}}>
            <h2 className='h-get-in-touch'>Get in touch</h2>
            <form action='/test' method='post'>
                <div className='form'>
                <div className='inter-form'>
                    <label for='name'>Your name :</label>
                    <input type='text' id='name' name='user-name' />
                </div><div className='inter-form'>
                    <label for='email'>Email adress :</label>
                    <input type='email' id='email' name='user-email' />
                </div><div className='inter-form'>
                    <label for='message'>Message :</label>
                    <textarea id='message' name='user-message'></textarea>
                </div>
                <div>
                    <button type='button'><img className='img-send' src={send} alt='send logo' /></button>
                </div>
                </div>
            </form>
            <Footer />
            </div>
        </section>
    )
}
export default GetInTouch;