import '../styles/AboutMe.css'
import profile from '../assets/profil.jpg'
function AboutMe({tree, height, width}){

    // à mettre dans une fontion séparé
    window.addEventListener("load", function(e){
        let boxElement=document.querySelector('.about-me');
        createObserver(boxElement);
    },false);
    function createObserver(boxElement){
        let observer;
        let options={
            root:null,
            rootMargin: "0px",
            threshold: [0,0.25,0.5,0.75,1],
        }
        observer= new IntersectionObserver(handleIntersect, options);
        observer.observe(boxElement);
    }
    function handleIntersect(entries, observer){
        tree[1].state[1](entries[0].intersectionRatio>0.5);
    }
// à mettre dans une fontion séparé    
    // visibilityObserver('.about-me')

    return(
        <section className="about-me"  style={{width: width, height:height}}>
            <div id={tree[1].id} className='ab-gradient'style={{width: width, height:height}}>
                <div className='about-me-container'>
                    <h2 className='h-about-me'>About me</h2>
                    <img className="profilePict" src={profile} alt='profile'/>
                    <p className='p-about-me'>
                    Hi, i'm Emmanuel. <br/>Strong professionnal experience in the accounts, i'm learning now in computer development/AI. Integrating a project, find a solution for implementing a idea, see it blossom under our finger magically make me happy. I love create something and people interact with, use, work and progress automatically.
                    </p>
                </div>
            </div>

        </section>
    )
}
export default AboutMe;