import github from '../assets/follow-me/github.png'
import linkedin from '../assets/follow-me/LinkekIn.png'
import stackoverflow from '../assets/follow-me/stackoverflow.png'

export const network=[
    {
        name:'Stack Overflow',
        logo:stackoverflow,
        url:'https://stackoverflow.com/users/18791613/emmanuel-7bd'
    },
    
    {
        name:'GitHub',
        logo:github,
        url:'https://github.com/Emmanuel-7bd'
    },
    
    {
        name:'LinkedIn',
        logo:linkedin,
        url:'https://www.linkedin.com/in/emmanuel-verheye-13254a237/'
    }
    
]