import '../styles/Footer.css'
import {network} from '../datas/network.js'

function Footer(){
    return(
        <div className='footer'>
        <ul className='network'>
            {
                network.map(({name,logo,url})=>
                <li className='network-logo' key={name}>
                <a href={url}><img className='logo-footer' src={logo} alt={`${name} logo`}  /></a>
                </li>)
            }
        </ul>
        <p className='p-footer'>powered by <a href='mailto:Emmanuel-7bd@comptacite.be'> Emmanuel-7bd</a></p>
        </div>
    )
}

export default Footer;