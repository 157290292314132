import '../styles/App.css';
import Home from './Home';
import { useState, useEffect } from 'react';
import AboutMe from './AboutMe';
import Skills from './Skills';
import GetInTouch from './GetInTouch';
import Nav from './Nav';

// Calcul en temps réel des dimensions du viewport pour adapter les dimensions de chaque section
function getWindowDimensions() {
  let { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const {height,width}=useWindowDimensions();
  
  const tree=[
    {
      name:'Home',
      state:useState(true),//Section isVisible?
      id:0,
    },
    {
      name:'About me',
      state:useState(false),
      id:1,
    },
    {
      name:'Skills',
      state:useState(false),
      id:2,
    },
    {
      name:'Get in touch',
      state:useState(false),
      id:3,
    },
  ]
  
  return (
    <div className="app">     
      <Nav tree={tree} height={height} width={width} />
      <Home tree={tree} height={height} width={width} />
      <AboutMe tree={tree} height={height} width={width} />
      <Skills tree={tree} height={height} width={width} />
      <GetInTouch tree={tree} height={height} width={width} />
    </div>
  );
}

export default App;
