import '../styles/Skill.css'

function Skill({name, logo, state}){
    return(
        <div>
            <li className="skill-item">
                <img className='skill-item-logo' src={logo} alt={`${name} cover`}/>
                <div className='skill-progress'>
                    <div className='skill-name'>{name}</div>
                    <div className='skill-container-state'>
                        <div className='skill-state' style={{width:state-4 +'%'}}></div>
                    </div>
                    <div className='skill-state-p'>{state} %</div>
                </div>
            </li>
        </div>
    )
}
export default Skill